// import './App.css';
import { useState, useEffect } from 'react'
import Button from './Components/Button'
// import ButtonLink from './Components/ButtonLink'
import Projects from './Components/Projects'
import Current from './Components/Current'
// import ReactPlayer from 'react-player'
import {BsFillMoonStarsFill, BsFillSunFill, BsTerminalFill, BsWrenchAdjustableCircleFill, BsVimeo} from 'react-icons/bs'
import {AiFillTwitterCircle, AiFillLinkedin, AiFillGithub, AiFillBehanceCircle} from 'react-icons/ai'
import portrait from './Media/portrait-bw.png'
import Skills from './Components/Skills'

function App() {
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    const timeNow = new Date();
    const tnHour = timeNow.getHours();

    if (tnHour > 18 || tnHour < 6) {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, []);

  // const devText = `designer(() => { developer })`;
  // const devText = `(Developer & Designer)`;

  return (
    // <div className={darkMode ? "dark transition-all duration-300" : "transition-all duration-300"}>
    <div className={`flex w-screen justify-center transition-all -z-50 duration-300 ${darkMode ? 'dark bg-slate-950' : 'bg-slate-50'}`}>
      <main className='max-sm:mx-10 sm:w-10/12 md:w-11/12 lg:w-10/12 xl:w-8/12 transition-all duration-300 z-0'>
        <section className='min-h-screen mb-12 px-10'>
          <nav className='py-10 mb-12 flex flex-wrap justify-between gap-5 '>
              <h1 className='text-slate-950  sm:text-md md:text-xl lg:text-xl font-semibold my-auto dark:text-slate-50 max-sm:w-full max-sm:px-10 flex justify-between items-center transition-all duration-300 transition-width'>aaroncwilliams { darkMode ? <BsFillSunFill onClick={() => setDarkMode(!darkMode)} className='text-slate-50 ml-2 cursor-pointer'/> : <BsFillMoonStarsFill onClick={() => setDarkMode(!darkMode)} className='text-slate-950 ml-2 cursor-pointer '/> }</h1>

              <hr className='border-1 opacity-0 max-sm:opacity-10 max-sm:mx-10 max-sm:w-full transition-all duration-300'/>

              <ul className='flex items-center transition-width max-sm:w-full max-sm:justify-evenly transition-all duration-300'>
                <li><Button link="https://github.com/awil" text="GitHub" cstyle="flex justify-center align-middle text-sm sm:text-md md:text-md lg:text-md xl:text-lg 2xl:text-lg font-bold bg-gradient-to-r from-amber-400 to-amber-500 text-white transition-all duration-300 hover:text-slate-950 px-2 py-2 rounded-md  dark:text-slate-950 dark:hover:text-white" icon={<AiFillGithub className='relative my-auto mr-1' size={18}/>} /></li>
                <li><Button link="https://www.linkedin.com/in/aaroncwilliams/" text="LinkedIn" cstyle="flex justify-center align-middle text-sm sm:text-md md:text-md lg:text-md xl:text-lg 2xl:text-lg font-bold bg-gradient-to-r from-amber-400 to-amber-500 text-white transition-all duration-300 hover:text-slate-950 px-2 py-2 rounded-md ml-2 dark:text-slate-950 dark:hover:text-white" icon={<AiFillLinkedin className='relative my-auto mr-1' size={18}/>} /></li>
                <li><Button link="https://www.behance.net/aaroncwilliams" text="Behance" cstyle="flex justify-center align-middle text-sm sm:text-md md:text-md lg:text-md xl:text-lg 2xl:text-lg font-bold bg-gradient-to-r from-amber-400 to-amber-500 text-white transition-all duration-300 hover:text-slate-950 px-2 py-2 rounded-md ml-2 dark:text-slate-950 dark:hover:text-white" icon={<AiFillBehanceCircle className='relative my-auto mr-1' size={18}/>} /></li>
                
              </ul>
              
          </nav>
          <div className='text-center p-5'>
            <h2 className='greeting__title text-5xl py-2 font-semibold md:text-6xl'>Hi, I'm Aaron!</h2>
            {/* <h3 className='text-slate-950 font-mono text-2xl py-2 md:text-3xl dark:text-slate-50'><code>{devText}</code></h3> */}
            <p className='text-left my-5 py-5 text-gray-800 text-xl max-sm:leading-[2.1rem] sm:leading-[2.6rem] sm:text-2xl md:leading-[3.4rem] md:text-3xl lg:leading-[3.4rem] lg:text-4xl xl:leading-[4.4rem] xl:text-4xl max-w-xxl mx-auto dark:text-slate-300'>I'm a recent graduate in <strong>Application Development</strong> from Northwestern State University of Louisiana. I'm a <strong>full-stack developer</strong> with <strong>4 years</strong> of professional experience. I love writing code for <em>applications, websites, and utilities.</em> I'm also a proud dog chihuahua.</p>

            {/* <p className='text-left text-md my-5 py-5 leading-8 text-gray-800 md:text-xl max-w-xxl mx-auto dark:text-slate-300'>Hi, I’m Aaron! I recently graduated in Application Development from Northwestern State University, where I garnered a strong foundation in software development and programming across multiple platforms. With over 14 years of experience as a Multimedia Designer, I’ve become skilled at creating engaging visual experiences.
            <br/><br/>
            I’m passionate about problem-solving and creativity, always pushing myself to explore new learning opportunities and expand my skills. My goal is to combine my knowledge in technology and design to inspire others and drive growth personally and professionally.</p> */}
          </div>
          <div className='relative mx-auto mt-2 mb-6 bg-gradient-to-b from-amber-300 to-amber-600 rounded-full overflow-hidden w-80 h-80'>
              <img src={portrait} alt="Aaron Williams" className='object-cover w-full h-full' />
          </div>
          <div className='text-4xl flex justify-center gap-16 max-sm:gap-6 py-3 text-gray-600 md:text-5xl dark:text-slate-300'>
            <a href="https://www.linkedin.com/in/aaroncwilliams/" className='hover:text-amber-600 transition-all duration-300' target="_blank" rel="noreferrer"><AiFillLinkedin /></a>
            <a href="https://twitter.com/aaroncwilliams" className='hover:text-amber-600 transition-all duration-300' target="_blank" rel="noreferrer"><AiFillTwitterCircle /></a>
            <a href="https://github.com/awil" className='hover:text-amber-600 transition-all duration-300' target="_blank" rel="noreferrer"><AiFillGithub /></a>
            <a href="https://www.behance.net/aaroncwilliams" className='hover:text-amber-600 transition-all duration-300' target="_blank" rel="noreferrer"><AiFillBehanceCircle /></a>
            <a href="https://vimeo.com/aaroncwilliams" className='hover:text-amber-600 transition-all duration-300' target="_blank" rel="noreferrer"><BsVimeo /></a>
          </div>
        </section>
        <section className='pt-5 mt-10 mb-14'>
          <div>
            <h3 className='text-slate-950 text-3xl py-1 md:text-4xl dark:text-slate-50'>About Me</h3>
            <p className='text-left text-md my-5 py-5 sm:leading-8 text-gray-800 md:leading-8 md:text-xl dark:text-slate-300'>As a full stack developer, I bring a wide range of skills to the table. When it comes to the front-end, I'm all about creating awesome user interfaces using HTML, CSS, and JavaScript. I love working with newer frameworks, like React, enabling me to build dynamic and interactive web applications.
            <br/><br/>
            On the back-end, I have experience with server-side technologies such as PHP, MySQL, and Node.js. My knowledge extends to RESTful API development, ensuring seamless communication between front-end and back-end components.
            <br/><br/>
            My expertise extends beyond web development; I've also dabbled in Android app development, creating immersive mobile experiences using Java/Kotlin. Additionally, a little experience in iOS app development with Swift.
            <br/><br/>
            I'm an expert with design tools like Adobe Creative Suite, allowing me to create captivating visuals and engaging user interfaces. My ability to bridge the gap between development and design enables me to create holistic and cohesive digital experiences that are both visually stunning and highly functional.</p>
          </div>
          <div className='flex gap-2 sm:flex-col md:flex-col lg:flex-col xl:flex-row mb-12'>
            <div className='text-center shadow-lg p-6 rounded-xl my-4 md:basis-full lg:basis-1/2 xl:basis-1/2 dark:bg-slate-900'>
              <h2 className='text-amber-600 font-bold'><BsTerminalFill className='w-20 h-20 scale-50 mx-auto' /></h2>
              <h3 className='py-4 text-amber-600 text-xl font-bold'>Languages</h3>
              <p className='py-1 text-amber-600'>JavaScript</p>
              <p className='py-1 text-amber-600'>HTML</p>
              <p className='py-1 text-amber-600'>CSS</p>
              <p className='py-1 text-amber-600'>Java</p>
              <p className='py-1 text-amber-600'>Swift</p>
              <p className='py-1 text-amber-600'>PHP</p>
              <p className='py-1 text-amber-600'>MySQL</p>
            </div>
            <div className='text-center shadow-lg p-6 rounded-xl my-4 md:basis-full lg:basis-1/2 xl:basis-1/2 dark:bg-slate-900'>
                <h2 className='text-amber-600 font-bold'><BsWrenchAdjustableCircleFill className='w-20 h-20 scale-50 mx-auto' /></h2>
              <h3 className='py-4 text-amber-600 text-xl font-bold'>Tools</h3>
              <p className='py-1 text-amber-600'>IDEs (Eclipse, Xcode, Android Developer Studio)</p>
              <p className='py-1 text-amber-600'>Database Tools (e.g. SSMS, MySQL Workbench)</p>
              <p className='py-1 text-amber-600'>Command Line Interface Tools</p>
              <p className='py-1 text-amber-600'>Adobe Creative Suite</p>
              <p className='py-1 text-amber-600'>Blender 3D</p>
            </div>
          </div>
        </section>

        <Skills></Skills>

        <Projects></Projects>

        <Current></Current>

        <footer className='pt-5 mt-10 pb-12'>
          <div className='text-4xl flex justify-center gap-16 max-sm:gap-6 py-3 text-gray-600 md:text-5xl dark:text-slate-300'>
            <a href="https://www.linkedin.com/in/aaroncwilliams/" className='hover:text-amber-600 transition-all duration-300' target="_blank" rel="noreferrer"><AiFillLinkedin /></a>
            <a href="https://twitter.com/aaroncwilliams" className='hover:text-amber-600 transition-all duration-300' target="_blank" rel="noreferrer"><AiFillTwitterCircle /></a>
            <a href="https://github.com/awil" className='hover:text-amber-600 transition-all duration-300' target="_blank" rel="noreferrer"><AiFillGithub /></a>
            <a href="https://www.behance.net/aaroncwilliams" className='hover:text-amber-600 transition-all duration-300' target="_blank" rel="noreferrer"><AiFillBehanceCircle /></a>
            <a href="https://vimeo.com/aaroncwilliams" className='hover:text-amber-600 transition-all duration-300' target="_blank" rel="noreferrer"><BsVimeo /></a>
          </div>
        </footer>
        
      </main>
    </div>
  );
}

export default App;

import React from 'react'
import {skills} from '../constants/constants.js'
// import {BsPcDisplayHorizontal} from 'react-icons/bs'

// import ButtonLink from '../Components/ButtonLink'

export default function Skills () {
  return (
    <section className='pt-5 mt-10'>
        <div>
        <h3 className='text-slate-950 text-3xl py-1 md:text-4xl dark:text-slate-50'>Skills </h3>
        {/* <p className='text-left text-md my-5 py-5 leading-8 text-gray-800 md:text-xl dark:text-slate-300'>Here's a glimpse of projects I've worked on through freelance work, my career, and in school.</p> */}
        </div>
        <div className='flex flex-col gap-10 py-10 lg:flex-row lg:flex-wrap'>
                    <ul className='flex flex-wrap justify-center gap-2 text-lg dark:text-gray-800'>
                        {
                            skills.map((skill, index) => (
                                <li className='rounded-md border dark:border-amber-300 shadow-lg px-4 py-2 m-2 dark:bg-amber-800 dark:text-amber-400' key={index}>{skill}</li>
                            ))
                        }
                    </ul>
        </div>
    </section>
  )
}


import React from 'react'
import ReactPlayer from 'react-player'
import { CurrentProjects } from '../constants/constants.js'

const Current = () => {
  return (
    <section className='pt-5 mt-10'>
        <div>
        <h3 className='text-slate-950 text-3xl py-1 md:text-4xl dark:text-slate-50'>Current Projects</h3>
        <p className='text-left text-md my-5 py-5 leading-8 text-gray-800 md:text-xl dark:text-slate-300'>Here are some personal projects I'm currently working on in my spare time.</p>
        </div>
        <div className='flex flex-col gap-10 py-10 lg:flex-row lg:flex-wrap'>
            { CurrentProjects.map((card, index) => (
                <div key={index} className='rounded-lg shadow-lg basis-1/3 overflow-hidden flex-1 dark:bg-slate-900'>
                    { 
                        card.link && (<ReactPlayer url={card.link} width="100%" controls />)
                    }
                    { 
                        card.image && (<img src={card.image} alt={card.title} className='h-100' />)
                    }

                    <h3 className='text-slate-950 font-semibold px-10 pt-10 text-md dark:text-slate-50'>
                        {card.title}
                    </h3>
                    <p className='px-10 py-6 text-md leading-8 text-gray-800 dark:text-slate-300'>
                        {card.description}
                    </p>
                </div>
            ))}
        </div>
    </section>
  )
}

export default Current
import React from 'react';

const Button = ({ link, text }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
        <button className='flex justify-center align-middle text-md mb-10 px-5 py-2.5 font-bold bg-gradient-to-r from-amber-400 to-amber-500 text-white transition-all duration-300 hover:text-slate-950 rounded-md ml-8 dark:text-slate-950 dark:hover:text-white'>
            {text}
        </button>
    </a>
  );
};

export default Button;